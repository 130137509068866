<template>
  <section class="design">
    <p class="f-s-24 l-h-29 f-f-raleway font-bold f-c-1">{{ $t("message.urban_title") }}</p>

    <gap :height="21" />

    <p class="f-s-20 l-h-25 f-f-raleway font-bold f-c-1">{{ $t("message.urban_subtitle") }}</p>
    
    <gap :height="21" />

    <article class="f-s-14 f-f-arial f-c-1 l-h-25 pre-tag">
      {{ $t("message.urban_content") }}
    </article>

    <gap :height="40" />

    <div class="urban-image"></div>
  </section>
</template>
<script>
export default {
  
}
</script>
<style lang="scss" scoped type="text/scss">
  .design {
    .urban-image {
      height: 36.8rem;
      background-image: url("../../../assets/images/urban-design/services/urban-development.jpg");
      // background-image: url("../../../assets/images/urban-design/services/1.gif");
      background-size: cover;
      background-position: center center;
    }
  }
</style>