export default {
  projects: [
    {
      title: "Our Project",
      subtitle: "Southeast Asia",
      projects: [
        {
          id: "DANGA HEIGHTS",
          name: "DANGA HEIGHTS",
          location: "Malaysia",
          desc: "The Danga Heights project is located at the Iskandar Development Region of Johor Bahru, the main southern development corridor of the second largest city in Malaysia. Close to the Johor Bahru CBD and adjacent to Skudai Highway.",
          images: [
            require("@/assets/images/urban-design/danga/F_01.gif"),
            require("@/assets/images/urban-design/danga/F_02.gif"),
            require("@/assets/images/urban-design/danga/F_03.gif"),
            require("@/assets/images/urban-design/danga/F_04.gif"),
            require("@/assets/images/urban-design/danga/F_05.gif"),
          ],
        },
        {
          id: "DARA SAKOR",
          name: "DARA SAKOR",
          location: "Cambodia",
          desc: "Dara Sakor project is a 360 km² large city development located along the southwest coast of Koh Kong Province, Cambodia, which lies along the Gulf of Thailand. The site is situated about 260 km to Phnom Penh.",
          images: [
            // require("@/assets/images/urban-design/dara-sakor/American_7.jpg"),
            // require("@/assets/images/urban-design/dara-sakor/c01-Overall_aerial-010.jpg"),
            require("@/assets/images/urban-design/dara-sakor/c02-3.gif"),
            require("@/assets/images/urban-design/dara-sakor/c02-aerial.gif"),
            // require("@/assets/images/urban-design/dara-sakor/c04_04.JPG"),
            require("@/assets/images/urban-design/dara-sakor/c5.gif"),
            require("@/assets/images/urban-design/dara-sakor/C08.gif"),
            // require("@/assets/images/urban-design/dara-sakor/Cambodian_9.gif"),
            // require("@/assets/images/urban-design/dara-sakor/Japanese_7.gif"),
            require("@/assets/images/urban-design/dara-sakor/Site_plan_9.gif"),
          ],
        },
      ],
    },
  ]
}