<template>
  <div class="urban-design" v-iScroll>
    <gap :height="73" />

    <Design />

    <gap :height="73" />

    <Service />

    <template v-for="item in langPackage.projects">
      <OnSaleProjects :key="item.title" :list="item" />
    </template>

    <gap :height="174" />
  </div>
</template>
<script>
import jsonEn from "./json/json-en";
import jsonZh from "./json/json-zh";
import Design from "./components/Design.vue";
import Service from "./components/Services.vue";
import OnSaleProjects from "@/views/property-dev/components/OnSaleProjects.vue"

export default {
  components: { Design, Service, OnSaleProjects },
  data() {
    return {
      langPackage: '',
    };
  },
  computed: {
    lang() {
      return this.$store.state.lang;
    }
  },
  watch: {
    lang(newVal) {
      this.langPackage = newVal === 'en' ? jsonEn : jsonZh;
    }
  },
  created() {
    this.langPackage = this.lang === 'en' ? jsonEn : jsonZh;
  }
};
</script>
<style lang="scss" scoped type="text/scss">
.urban-design {
  padding: 0 10rem;
}
</style>