<template>
  <section class="services">
    <p class="f-s-24 l-h-29 f-f-raleway font-bold f-c-1">{{ $t("message.urban_service_title")}}</p>

    <gap :height="42" />

    <p class="f-s-20 f-f-arial font-bold f-c-1 l-h-28">{{ $t("message.urban_service_content") }}</p>
    
    <gap :height="41" />

    <ul ref="services" class="flex-row flex-wrap">
      <template v-for="item in list">
        <li 
          :key="item.label"
          :style="{width: `${serviceBox.width}px`, height: `${serviceBox.height}px`}"
          class="f-s-18 f-f-arial font-bold f-c-1 flex-row align-items-center justify-content-center position-relative">
          <p class="position-absolute flex-row align-items-center justify-content-center">{{ item.label }}</p>
          <p class="position-absolute" :style="{backgroundImage: 'url(' + item.imageSrc + ')'}"></p>
        </li>
      </template>
    </ul>
  </section>
</template>
<script>
export default {
  data() {
    return {
      serviceBox: {
        width: 0,
        height: 0
      },
      timer: null,
      listener: null,
    }
  },
  computed: {
    list() {
      return [
        {label: this.$t('message.urban_services.study'), imageSrc: require('@/assets/images/urban-design/services/2.jpg')},
        {label: this.$t('message.urban_services.planning'), imageSrc: require('@/assets/images/urban-design/services/6.jpg')},
        {label: this.$t('message.urban_services.design'), imageSrc: require('@/assets/images/urban-design/services/4.jpg')},
        {label: this.$t('message.urban_services.architectural'), imageSrc: require('@/assets/images/urban-design/services/construction-design.jpg')},
        {label: this.$t('message.urban_services.sustainable'), imageSrc: require('@/assets/images/urban-design/services/5.jpg')},
        {label: this.$t('message.urban_services.transportation'), imageSrc: require('@/assets/images/urban-design/services/tansport.png')},
        {label: this.$t('message.urban_services.resource'), imageSrc: require('@/assets/images/urban-design/services/water-management.jpg')},
        {label: this.$t('message.urban_services.wastewater'), imageSrc: require('@/assets/images/urban-design/services/7.jpg')},
        {label: this.$t('message.urban_services.stormwater'), imageSrc: require('@/assets/images/urban-design/services/11.jpg')},
        {label: this.$t('message.urban_services.sidewide'), imageSrc: require('@/assets/images/urban-design/services/13.png')},
        {label: this.$t('message.urban_services.solid'), imageSrc: require('@/assets/images/urban-design/services/elec-design.jpg')},
        {label: this.$t('message.urban_services.power'), imageSrc: require('@/assets/images/urban-design/services/12.jpg')},
      ]
    }
  },
  created() {
    this.timer = setTimeout(() => { this.setBoxSize(); }, 100);
    this.listener = window.addEventListener('resize', this.setBoxSize);
  },
  destroyed() {
    clearTimeout(this.timer);
  },
  methods: {
    setBoxSize() {
      const width = this.$refs.services.clientWidth;
      this.serviceBox.width = (width - 101)/6;
      this.serviceBox.height = (this.serviceBox.width*170)/178;
    }
  }
}
</script>
<style lang="scss" scoped type="text/scss">
  .services {
    ul {
      li {
        margin-right: 20px;
        margin-bottom: 20px;
        text-align: center;
        line-height: 2.8rem;
        &:hover {
          background-color: #dfdcdf;
        }
      }
      li:nth-child(6), li:nth-child(12) {
        margin-right: 0;
      }
      p {
        width: 100%;
        height: 100%;
      }
      p:first-child {
        z-index: 10;
      }
      p:last-child {
        opacity: .4;
        background-size: cover;
        background-position: center center;
      }
    }
  }
</style>