export default {
  projects: [
    {
      title: "我们的项目",
      subtitle: "东南亚",
      projects: [
        {
          id: "DANGA HEIGHTS",
          name: "DANGA HEIGHTS",
          location: "马来西亚",
          desc: `Danga Heights项目位于马来西亚第二大城市——柔佛州州府新山市的依斯干达特区，靠近新山中央商务区，毗邻Skudai Highway (士古来快速公路)，项目整体占地约60公顷，计划分4期开发，开发周期10-12年，首期发展规划面积15.4公顷。\n
          Danga Heights建成后将成为一个“国际化混合生态社区”，也是新山最具发展潜力的大型综合性开发项目。根据规划和功能定位，该项目将成为马来西亚充满活力的电子商务、体验经济集中地；时尚共享、生态环保的优质生活圈以及新山最具影响力的5G应用智慧创新社区。
          `,
          images: [
            require("@/assets/images/urban-design/danga/F_01.gif"),
            require("@/assets/images/urban-design/danga/F_02.gif"),
            require("@/assets/images/urban-design/danga/F_03.gif"),
            require("@/assets/images/urban-design/danga/F_04.gif"),
            require("@/assets/images/urban-design/danga/F_05.gif"),
          ],
        },
        {
          id: "DARA SAKOR",
          name: "七星海",
          location: "柬埔寨",
          desc: `七星海项目占地360平方公里，位于柬埔寨国公省西南海滨，紧邻泰国湾。项目距首都金边约260公里，4E级七星海国际机场在建，2-3小时空中航程可达东盟各国；4-5小时可抵中国北京、上海以及日韩等重要客源地。项目还规划有10万吨级国际集装箱码头、万吨级邮轮码头、2000吨货运码头及游艇码头。构筑海陆空全方位的客流、物流服务。\n
          整个项目分为三大片区。七星海国际新区约100平方公里，打造柬埔寨全新综合旅游目的地。世贸新区约60平方公里，规划为东南亚新兴商贸金融中心。未来新区约200平方公里，将成为未来智能科技新区。塑造“生态+人文”的特色海滨城市环境；导入旅游度假、商业服务、科技生产等高端功能；关注多样宜居的生活环境设计，吸引人才留驻是项目规划的三大重要方向。
          `,
          images: [
            // require("@/assets/images/urban-design/dara-sakor/American_7.jpg"),
            // require("@/assets/images/urban-design/dara-sakor/c01-Overall_aerial-010.jpg"),
            require("@/assets/images/urban-design/dara-sakor/c02-3.gif"),
            require("@/assets/images/urban-design/dara-sakor/c02-aerial.gif"),
            // require("@/assets/images/urban-design/dara-sakor/c04_04.JPG"),
            require("@/assets/images/urban-design/dara-sakor/c5.gif"),
            require("@/assets/images/urban-design/dara-sakor/C08.gif"),
            // require("@/assets/images/urban-design/dara-sakor/Cambodian_9.gif"),
            // require("@/assets/images/urban-design/dara-sakor/Japanese_7.gif"),
            require("@/assets/images/urban-design/dara-sakor/Site_plan_9.gif"),
          ],
        },
      ],
    },
  ]
}